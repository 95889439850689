<template>
  <WsMain>
    <WsCrud
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :infiniteScroll="true"
      inRowBtnRead
    ></WsCrud>
  </WsMain>
</template>

<script>
import ShopNotice from "@/__vue2stone_cms/models/news";
export default {
  data: () => ({
    modelName: ShopNotice.modelName,
    fields: ShopNotice.fields,
    label: ShopNotice.label,
    showFields: ["title", "id", "tags"],
  }),
};
</script>